const Message = () => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="1280.000000pt"
    height="821.000000pt"
    viewBox="0 0 1280.000000 821.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,821.000000) scale(0.100000,-0.100000)"
      fill="#000000"
      stroke="none"
    >
      <path
        d="M615 8204 c-129 -27 -208 -67 -284 -143 -31 -31 -72 -83 -90 -114
-17 -30 -59 -83 -92 -118 -72 -73 -122 -170 -138 -267 -8 -48 -11 -1050 -9
-3612 3 -3474 3 -3546 22 -3595 63 -162 179 -276 336 -331 54 -19 184 -19
5945 -19 5037 0 5897 2 5940 14 129 37 248 129 314 244 17 30 59 83 92 118 72
73 122 170 138 267 8 48 11 1050 9 3612 -3 3472 -3 3546 -22 3595 -62 159
-172 269 -331 331 -49 19 -148 19 -5930 20 -3234 1 -5889 0 -5900 -2z m10595
-704 c-1 -8 -4713 -2863 -4724 -2863 -10 1 -4703 2846 -4718 2861 -4 4 2119 7
4718 7 2598 0 4724 -2 4724 -5z m928 -4886 l-3 -1556 -850 767 c-467 422
-1394 1258 -2058 1858 -1024 925 -1205 1092 -1190 1101 85 47 4072 2471 4083
2482 13 13 15 -158 18 -1541 1 -855 1 -2255 0 -3111z m-9373 3276 c1144 -694
2081 -1263 2083 -1265 1 -1 -84 -81 -190 -177 -395 -357 -3964 -3581 -3980
-3596 -17 -14 -18 137 -18 3153 0 2533 3 3165 13 3157 6 -5 948 -578 2092
-1272z m5356 -1497 c157 -142 791 -715 1409 -1273 618 -558 1457 -1316 1865
-1683 673 -608 740 -672 743 -702 3 -31 1 -33 -21 -27 -14 4 -436 378 -938
832 -1618 1461 -3090 2790 -3251 2934 -154 139 -156 141 -136 159 11 9 25 17
32 17 6 0 140 -116 297 -257z m282 -636 c452 -408 1382 -1248 2067 -1866 685
-618 1260 -1137 1277 -1155 l33 -31 -5430 0 c-2987 0 -5429 3 -5427 7 2 6 899
818 3062 2772 572 516 1054 952 1071 968 l31 29 509 -309 c280 -170 525 -317
544 -327 55 -29 249 -33 321 -7 30 11 290 164 579 340 289 176 528 321 533
321 4 1 377 -333 830 -742z"
      />
    </g>
  </svg>
);
export default Message;
