const VideosIcon = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.2374 11.2543C15.912 11.0611 15.5413 10.9574 15.1629 10.9539C14.7845 10.9504 14.4119 11.0472 14.083 11.2345C13.7541 11.4217 13.4807 11.6927 13.2905 12.0199C13.1003 12.347 13.0002 12.7187 13.0003 13.0972V22.9029C13.0002 23.2813 13.1003 23.653 13.2905 23.9802C13.4807 24.3074 13.7541 24.5784 14.083 24.7656C14.4119 24.9528 14.7845 25.0496 15.1629 25.0461C15.5413 25.0426 15.912 24.939 16.2374 24.7457L25.5303 19.2286C25.7435 19.1021 25.92 18.9223 26.0427 18.7069C26.1654 18.4915 26.2299 18.2479 26.2299 18C26.2299 17.7522 26.1654 17.5085 26.0427 17.2931C25.92 17.0777 25.7435 16.898 25.5303 16.7715L16.2374 11.2543ZM0.857422 18C0.857422 8.53289 8.53314 0.857178 18.0003 0.857178C27.4674 0.857178 35.1431 8.53289 35.1431 18C35.1431 27.4672 27.4674 35.1429 18.0003 35.1429C8.53314 35.1429 0.857422 27.4672 0.857422 18ZM18.0003 3.00003C9.71599 3.00003 3.00028 9.71575 3.00028 18C3.00028 26.2843 9.71599 33 18.0003 33C26.2846 33 33.0003 26.2843 33.0003 18C33.0003 9.71575 26.2846 3.00003 18.0003 3.00003Z"
      fill="white"
    />
  </svg>
);

export default VideosIcon;
