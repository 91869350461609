import React, {useEffect, useState} from 'react';
import {GoogleMap, useJsApiLoader, LoadScript} from '@react-google-maps/api';
import {Marker} from '@react-google-maps/api';
const containerStyle = {
  width: '600px',

  height: '600px',
};

// const center = {
//   lat: 20.80431760063241,
//   lng: 20.999627083539963,
// };

function Map({position, onDrag = () => {}}) {
  const [center, setCenter] = useState({
    lat: position.latitude,
    lng: position.longitude,
  });
  const {isLoaded, loadError} = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyBNWTvGcBUrMPokRH2BU6TzHZ84XwXf2gU',
  });

  console.log(isLoaded);

  // const onLoad = React.useCallback(function callback(map) {
  //   if (google) {
  //     const bounds = new google.maps.LatLngBounds();
  //     if (bounds && map) {
  //       map.fitBounds(bounds);
  //     }
  //     // setCenter({
  //     //   lat: position.latitude,
  //     //   lng: position.longitude,
  //     // });
  //     setMap(map);
  //   }
  // }, []);

  // const onUnmount = React.useCallback(function callback(map) {
  //   // setMap(null);
  // }, []);

  return (
    isLoaded && (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={{lat: position.latitude, lng: position.longitude}}
        zoom={5}>
        <>
          <Marker
            on
            position={{lat: position.latitude, lng: position.longitude}}
            draggable={true}
            center
            onDragEnd={(e) => {
              onDrag(e);
            }}
          />
        </>
      </GoogleMap>
    )
  );
}

export default React.memo(Map);
