import {responseHandler} from '../helpers';
import fetchRequest from '../utils/fetchRequest';

export const createPodcast = async (data) => {
  const response = await fetchRequest(`api/admin/createPodcast`, 'POST', data);
  console.log('response', response);

  const error = responseHandler(response, 'create podcast');

  if (!error) {
    return true;
  } else alert(error);
};
