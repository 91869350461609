import {responseHandler} from '../helpers';
import fetchRequest from '../utils/fetchRequest';

export const getAllUsers = async () => {
  const response = await fetchRequest(`api/admin/getAllUsers`, 'GET');

  const error = responseHandler(response, 'fetch all users');

  if (!error) {
    return response.users;
  }
};
