import {object, string} from 'yup';

const createPodcastSchema = object({
  url: string().required(),
  author: string().required(),
  category: object().shape({
    _id: string(),
    name: string(),
  }),
  user: object().shape({
    _id: string(),
    name: string(),
  }),
});

export default createPodcastSchema;
