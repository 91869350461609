import {Formik} from 'formik';
import React, {useCallback, useEffect, useState} from 'react';
import Button from '../Button';
import Input from '../Input';
import Map from '../Map';
import Modal from '../ModalLayout';
import Uploader from '../Uploader';
import Autocomplete from '../Autocomplete';
import {createPodcast as createPodcastFunc} from '../../APIs/createPodcast';

import styles from './styles.module.scss';
import {getAllUsers} from '../../APIs/getAllUsers';
import {getAllCategories} from '../../APIs/getAllCategories';
import VALIDATIONS from '../../validation';

const PodcastCreateModal = ({isShow, onClose}) => {
  const [createPodcast, setCreatePodcast] = useState({
    location: {
      latitude: 40.0220055,
      longitude: -98.0666657,
    },
  });
  const [iseLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [categories, setCategories] = useState([]);

  const onBootstrap = useCallback(async () => {
    if (isShow) {
      try {
        setIsLoading(true);
        const users = await getAllUsers();
        const categories = await getAllCategories();

        setCategories(categories);
        setUsers(users);
      } catch (e) {
        console.log(`error parse users ${e}`);
      } finally {
        setIsLoading(false);
      }
    }
  }, [isShow]);

  useEffect(() => {
    onBootstrap();
  }, [onBootstrap]);

  const onCreatePodcast = async (values) => {
    const data = {
      ...values,
      ...createPodcast,
    };

    const response = await createPodcastFunc(data);
    if (response) {
      onClose();
      window.location.reload();
    }
  };

  return (
    <Modal closeBtn visible={isShow} onClosePopup={onClose}>
      <div className={styles.detailsTitle}>Create Podcast</div>
      <div className={styles.detailsRow}>
        <Formik
          validateOnBlur={true}
          validateOnChange={false}
          initialValues={{
            author: '',
            url: '',
            user: null,
            isAuthor: false,
            category: null,
            description: '',
            locationInfo: '',
          }}
          validationSchema={VALIDATIONS.createPodcastSchema}
          onSubmit={async (values) => {
            onCreatePodcast(values);
          }}>
          {({errors, values, setFieldValue, handleSubmit, handleChange}) => (
            <form
              onSubmit={handleSubmit}
              // className={styles.signIn__inner}
              className={styles.modalFormik}>
              <div className={styles.signIn__form}>
                <div className={styles.inputTitle}> Author </div>
                <Input
                  formHelper
                  placeholder="Author"
                  name="author"
                  value={values.author}
                  error={errors.author}
                  onChange={setFieldValue}
                />
                <div className={styles.inputTitle}> Link </div>
                <Input
                  formHelper
                  placeholder="Link"
                  name="url"
                  value={values.url}
                  error={errors.url}
                  onChange={setFieldValue}
                />
                <div className={styles.inputTitle}> Description </div>
                <Input
                  formHelper
                  placeholder="Description"
                  name="description"
                  value={values.description}
                  error={errors.description}
                  onChange={setFieldValue}
                />
                <div className={styles.inputTitle}> Location Info </div>
                <Input
                  formHelper
                  placeholder="Location Info"
                  name="locationInfo"
                  value={values.locationInfo}
                  error={errors.locationInfo}
                  onChange={setFieldValue}
                />
                <div className={styles.inputTitle}>Category</div>
                <Autocomplete
                  placeholder="Category"
                  name="category"
                  data={categories}
                  onSelect={(category) => {
                    setFieldValue('category', category);
                  }}
                  initValue={values['category']?.name}
                />

                <div className={styles.inputTitle}>User</div>
                <Autocomplete
                  placeholder="User"
                  name="user"
                  data={users}
                  onSelect={(user) => {
                    setFieldValue('user', user);
                  }}
                  initValue={values['user']?.name}
                />

                <div className={styles.checkbox__wrapper}>
                  <input
                    name="isAuthor"
                    type="checkbox"
                    checked={values.isAuthor}
                    onChange={() => setFieldValue('isAuthor', !values.isAuthor)}
                  />
                  <p className={styles.checkbox__label}>Is Author</p>
                </div>

                <Button type="submit">
                  <span>Create</span>
                </Button>
              </div>
            </form>
          )}
        </Formik>
        <Map
          position={createPodcast.location}
          onDrag={(e) => {
            setCreatePodcast({
              location: {
                latitude: e.latLng.lat(),
                longitude: e.latLng.lng(),
              },
            });
          }}
        />
      </div>
    </Modal>
  );
};

export default PodcastCreateModal;
