const EditIcon = () => (
  <svg
    width="10"
    height="12"
    viewBox="0 0 10 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.85754 1.84859L7.86628 0.859669C7.41089 0.404413 6.6727 0.404413 6.2173 0.859669L1.96774 5.11623C1.8356 5.2465 1.73645 5.40641 1.67853 5.5827L0.824884 8.30923C0.77288 8.47471 0.816987 8.65538 0.939395 8.77827C1.0618 8.90117 1.24229 8.946 1.40797 8.89465L4.13217 8.03868C4.30823 7.98143 4.46813 7.88309 4.59865 7.7518L8.85754 3.49524C9.31124 3.04011 9.31124 2.30371 8.85754 1.84859ZM7.37209 2.34338L3.40707 6.30839C3.22092 6.45518 2.95427 6.43899 2.78723 6.27076C2.6202 6.10253 2.60591 5.83578 2.75401 5.65067L6.71903 1.68565C6.83694 1.56774 7.00879 1.5217 7.16986 1.56485C7.33092 1.60801 7.45673 1.73382 7.49988 1.89488C7.54304 2.05595 7.49699 2.2278 7.37909 2.34571L7.37209 2.34338ZM1.38473 11.7142H8.61505C8.93708 11.7142 9.19814 11.4532 9.19814 11.1311C9.19814 10.8091 8.93708 10.548 8.61505 10.548H1.38473C1.06269 10.548 0.801636 10.8091 0.801636 11.1311C0.801636 11.4532 1.06269 11.7142 1.38473 11.7142Z"
      fill="white"
    />
  </svg>
);

export default EditIcon;
