const SearchIcon = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.3615 2C7.05323 2 2.75 6.20819 2.75 11.3993C2.75 16.5903 7.05323 20.7985 12.3615 20.7985C14.6319 20.7985 16.7184 20.0287 18.363 18.7415L21.4871 21.7886L21.5702 21.8586C21.8602 22.0685 22.2714 22.0446 22.5339 21.7873C22.8226 21.5043 22.822 21.0459 22.5325 20.7636L19.4452 17.7523C21.0149 16.0794 21.9731 13.8487 21.9731 11.3993C21.9731 6.20819 17.6698 2 12.3615 2ZM12.3615 3.44774C16.8522 3.44774 20.4926 7.00776 20.4926 11.3993C20.4926 15.7908 16.8522 19.3508 12.3615 19.3508C7.87086 19.3508 4.23044 15.7908 4.23044 11.3993C4.23044 7.00776 7.87086 3.44774 12.3615 3.44774Z"
      fill="#000000"
    />
  </svg>
);

export default SearchIcon;
