import {responseHandler} from '../helpers';
import fetchRequest from '../utils/fetchRequest';

export const getAllCategories = async () => {
  const response = await fetchRequest(`api/admin/getAllCategories`, 'GET');

  const error = responseHandler(response, 'fetch all categories');

  if (!error) {
    return response.categories;
  }
};
