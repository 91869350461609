import React, {useEffect, useMemo, useState} from 'react';

import styles from './styles.module.scss';

const Autocomplete = ({
  data = [],
  onSelect,
  initValue = '',
  placeholder = '',
  name = '',
}) => {
  const [value, setValue] = useState(initValue);
  const [isFocus, setIsFocus] = useState(false);

  useEffect(() => {
    if (value !== initValue && initValue) {
      setValue(initValue);
    }
  }, [initValue]);

  const filtered = useMemo(
    () =>
      data.filter(({name = ''}) => {
        return name.indexOf(value) !== -1 && value !== name;
      }),
    [data, value],
  );

  useEffect(() => {
    if (initValue !== value) {
      const item = data.find(({name}) => name === value);

      if (item) {
        onSelect(item);
      }
    }
  }, [value, data, initValue]);

  return (
    <div className={styles.autocomplete__wrapper}>
      <input
        onFocus={() => setIsFocus(true)}
        onBlur={() => setTimeout(() => setIsFocus(false), 100)}
        value={value}
        name={name}
        className={styles.autocomplete__input}
        type="input"
        placeholder={placeholder}
        onChange={({target}) => {
          setValue(target.value);
          onSelect(null);
        }}
      />
      {isFocus && !!filtered.length && (
        <ul className={styles.autocomplete__list}>
          {filtered.map((item) => (
            <li
              role="button"
              onClick={() => {
                onSelect(item);
                setValue(item.name);
              }}
              key={item._id}
              className={styles.autocomplete__item}>
              {item.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Autocomplete;
